import React from 'react';
import './section-home-members.scss';
import Fab from '@mui/material/Fab';

export default function sectionHomeMembers({ infoContent, isMobile }) {
  const {
    environmentalImage,
    environmentalTitle,
    environmentalDescription,
    sostenibilityLink
  } = infoContent;

  return (
    <section id="home-sustainibility" className="f-content-important-home-newM">
      
      {isMobile ? (
        <h1 className="f-content-important-title">{environmentalTitle}</h1>
      ) : null}
      <div className="f-content-important-image">
        <div
          className="f-content-important-image-img"
          style={{
            background: `url("${environmentalImage.file.url}") no-repeat`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
          }}
        ></div>
      </div>
      <div className="f-content-important-text">
        {!isMobile ? (
          <h1 className="f-content-important-title">
            {environmentalTitle}
          </h1>
        ) : null}

        <div className="f-content-important-description">
          {environmentalDescription.environmentalDescription}
        </div>
        <a href={sostenibilityLink} className={"nostyle"}>
          <Fab
            variant="extended"
            size="medium"
            aria-label="add"
            className="f-content-important-redirection"
          >
            CONÓCELOS
        </Fab>
        </a>
      </div>
    </section>
  );
}
